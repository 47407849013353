
import DisplayStory from "@evercam/shared/components/DisplayStory"
import { InfoPage } from "@evercam/shared/types"
import { mapStores } from "pinia"
import { useGoStore } from "@/stores/go"

export default {
  components: { DisplayStory },
  computed: {
    ...mapStores(useGoStore),
    slug() {
      return `info-pages/${InfoPage.EvercamGo}`
    },
  },
}


import NdaIcon from "@/components/NdaIcon"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"
export default {
  name: "ProjectListItem",
  components: {
    NdaIcon,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isUnderNda() {
      return this.project?.featureFlags?.includes(ProjectFeatureFlag.NdaMc)
    },
  },
}
